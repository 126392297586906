<template>
  <h1>Register</h1>
  <p>
    Hier kannst du einen Account erstellen, um deine Daten zu sichern. Wir
    wollen keinerlei Personenbezogene Daten speichern, deshalb kannst keinen
    eigenen Namen eingeben. Wir speichern auch keine Email von dir, also merke
    dir deinen Namen und dein Passwort! Du kannst es nicht automatisch
    zurücksetzen.
  </p>

  <form class="flexbox" @submit.prevent="submit">
    <div class="flexbox-2">
      <input
        class="flexbox-item"
        v-model="name"
        placeholder="username"
        required
        disabled
      />
      <input
        class="flexbox-item"
        v-model="password"
        placeholder="password"
        type="password"
        required
      />
    </div>
    <button class="flexbox-item secondary-btn" @click="generateName()">
      Alternativen Namen generieren
    </button>
    <button class="flexbox-item" type="submit">Account anlegen</button>
  </form>
</template>

<script>
import {
  uniqueNamesGenerator,
  adjectives,
  animals,
} from "unique-names-generator";
import router from "@/router";

export default {
  data() {
    return {
      name: uniqueNamesGenerator({
        dictionaries: [adjectives, animals],
      }),
      password: "",
    };
  },
  methods: {
    async submit() {
      await fetch(process.env.VUE_APP_API_HOST + "/users/add", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: this.name, password: this.password }),
      });
      router.push("/login");
    },
    generateName() {
      this.name = uniqueNamesGenerator({
        dictionaries: [adjectives, animals],
      });
    },
  },
};
</script>

<style scoped>
.flexbox {
  /* max-width: 200px; */
  display: flex;
  flex-direction: column;
}
.flexbox-2 {
  /* max-width: 200px; */
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* background-color: grey; */
}

.flexbox-item {
  margin: 0.2rem;
  padding: 0.4rem;
  flex-grow: 1;
}

button {
  border-radius: 0px;
  border: none;
  background-color: var(--primary-color);
  font-weight: bold;
  justify-content: center;
}
button.secondary-btn{
  background-color: var(--secondary-color);
}
input {
  border-radius: 0px;
  border-style: solid;
  border-color: var(--tertiary-color);
  /* border: none; */
}

button a {
  text-decoration: none;
}
</style>
